<template>
    <div class="column-start h-100">
        <div class="row-between mb-40">
            <div
                class="lg-12 row-baseline pointer mb-8 f-15-grey"
                @click="$router.push({ name: 'Analytics' })"
            >
                <arrow-shewron
                    :transform="180"
                    width="14"
                    height="14"
                    class="i-green mr-2"
                />
                Analytics
            </div>
            <div class="column-start lg-6 sm-12">
                <h1 class="f-28-black f-black">Most active users by taps</h1>
            </div>
        </div>
        <div v-if="!loading" class="row-start h-100">
            <div v-if="metricInfo.length" class="w-100">
                <dynamic-table
                    :header="tableData.tableHeader"
                    :tableData="metricInfo"
                    :optionsForRender="tableData.optionsForRender"
                />
                <div class="row-center pv-8">
                    <load-more
                        v-if="isMorePages"
                        :status="loadingStatus"
                        @click="getData(null, false)"
                    />
                </div>
            </div>
            <div v-else class="w-100 s-align-5">
                <p class="f-18-grey">Your page is empty</p>
            </div>
        </div>
        <loader v-else />
    </div>
</template>

<script>
import { DynamicTable } from "@/components";
import { ArrowShewron } from '@/icons';

export default {
    name: "MostActiveUsers",
    components: {
        DynamicTable,
        ArrowShewron,
    },
    data() {
        return {
            loading: false,
            loadingStatus: false,
            metricInfo: [],
            isMorePages: false,
            pageNumber: 1,
            tableData: {
                tableHeader: [
                    "Name",
                    "Email",
                    "Taps count",
                ],
                optionsForRender: [
                    "full_name",
                    "email",
                    "count",
                ],
            },
        };
    },
    methods: {
        getData(period = null, showloader = true) {
            if (showloader) {
                this.loading = true;
            }

            let params = {
                page: this.pageNumber,
            };

            this.pageNumber++;
            this.loadingStatus = true;

            this.$store
                .dispatch("analytics/GET_MOST_ACTIVE_CARDS", params)
                .then((data) => {
                    this.metricInfo.push(...data.data.data);
                    data.data.current_page === data.data.last_page
                        ? (this.isMorePages = false)
                        : (this.isMorePages = true);
                    this.loadingStatus = false;
                })
                .catch((error) => {
                    this.$store.dispatch(
                        "notifications/SHOW_REQUEST_ERROR_NOTIFICATION",
                        error
                    );
                })
                .finally(() => (this.loading = false));
        },
    },
    created() {
        this.getData(null);
    },
};
</script>
